export default [
  // {
  //   header: 'Apps & Pages',
  // },
  {
    title: 'Dashboard',
    icon: 'HomeIcon',
    route: { name: 'dashboard-homepage' },
  },
  {
    header: 'Tools',
  },
  {
    title: 'Themes',
    icon: 'AirplayIcon',
    route: { name: 'theme-list' },
  },
  {
    title: 'Attribute',
    icon: 'GithubIcon',
    route: { name: 'attribute-edit' },
  },
  {
    title: 'Blog',
    icon: 'BoldIcon',
    children: [
      {
        title: 'Blog Categories',
        route: { name: 'blog-category-list' },
      },
      {
        title: 'Add new category',
        route: { name: 'blog-category-add' },
      },
      {
        title: 'Blog',
        route: { name: 'blog-list' },
      },
      {
        title: 'Add new blog',
        route: { name: 'blog-add' },
      },
    ],
  },
  {
    title: 'Customer Fake',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Customer Fake',
        route: { name: 'customer-fake-list' },
      },
      {
        title: 'Add new customer-fake',
        route: { name: 'customer-fake-add' },
      },
    ],
  },
  {
    title: 'Gallery',
    icon: 'ImageIcon',
    children: [
      {
        title: 'Gallery Categories',
        route: { name: 'gallery-category-list' },
      },
      {
        title: 'Add new category',
        route: { name: 'gallery-category-add' },
      },
      {
        title: 'Gallery',
        route: { name: 'gallery-list' },
      },
      {
        title: 'Add new Gallery',
        route: { name: 'gallery-add' },
      },
    ],
  },
  {
    title: 'FAQ',
    icon: 'HelpCircleIcon',
    children: [
      {
        title: 'FAQ',
        route: { name: 'faq-list' },
      },
      {
        title: 'Add new FAQ',
        route: { name: 'faq-add' },
      },
    ],
  },
  {
    title: 'Origin',
    icon: 'NavigationIcon',
    children: [
      {
        title: 'Origin',
        route: { name: 'origin-list' },
      },
      {
        title: 'Add new origin',
        route: { name: 'origin-add' },
      },
    ],
  },
  {
    title: 'Service',
    icon: 'GiftIcon',
    children: [
      {
        title: 'Service',
        route: { name: 'service-list' },
      },
      {
        title: 'Add new service',
        route: { name: 'service-add' },
      },
    ],
  },
  {
    title: 'Slider',
    icon: 'ColumnsIcon',
    children: [
      {
        title: 'Slider',
        route: { name: 'slider-list' },
      },
      {
        title: 'Add new slider',
        route: { name: 'slider-add' },
      },
    ],
  },
  {
    title: 'Supplier',
    icon: 'TruckIcon',
    children: [
      {
        title: 'Supplier',
        route: { name: 'supplier-list' },
      },
      {
        title: 'Add new supplier',
        route: { name: 'supplier-add' },
      },
    ],
  },
  {
    title: 'Recruitment',
    icon: 'AnchorIcon',
    children: [
      {
        title: 'Recruitment Categories',
        route: { name: 'recruitment-category-list' },
      },
      {
        title: 'Add new category',
        route: { name: 'recruitment-category-add' },
      },
      {
        title: 'Recruitment',
        route: { name: 'recruitment-list' },
      },
      {
        title: 'Add new Recruitment',
        route: { name: 'recruitment-add' },
      },
    ],
  },
  {
    title: 'Trademark',
    icon: 'ShoppingBagIcon',
    children: [
      {
        title: 'Trademark',
        route: { name: 'trademark-list' },
      },
      {
        title: 'Add new trademark',
        route: { name: 'trademark-add' },
      },
    ],
  },
  {
    title: 'Product',
    icon: 'ShoppingCartIcon',
    children: [
      {
        title: 'Product Categories',
        route: { name: 'product-category-list' },
      },
      {
        title: 'Add new category',
        route: { name: 'product-category-add' },
      },
      {
        title: 'Product',
        route: { name: 'product-list' },
      },
      {
        title: 'Add new product',
        route: { name: 'product-add' },
      },
    ],
  },
  // {
  //   header: 'Functions',
  // },
  // {
  //   title: 'Warehouse',
  //   icon: 'BoxIcon',
  //   children: [
  //     {
  //       title: 'Warehouse',
  //       route: { name: 'warehouse-list' },
  //     },
  //     {
  //       title: 'Add new Warehouse',
  //       route: { name: 'warehouse-add' },
  //     },
  //   ],
  // },
  // {
  //   title: 'Customer',
  //   icon: 'UserIcon',
  //   children: [
  //     {
  //       title: 'Customer',
  //       route: { name: 'customer-list' },
  //     },
  //     {
  //       title: 'Add new customer',
  //       route: { name: 'customer-add' },
  //     },
  //   ],
  // },
  // {
  //   title: 'Attribute',
  //   icon: 'GithubIcon',
  //   route: { name: 'attribute-edit' },
  // },
  // {
  //   title: 'Coupon',
  //   icon: 'ZapIcon',
  //   children: [
  //     {
  //       title: 'Coupon',
  //       route: { name: 'coupon-list' },
  //     },
  //     {
  //       title: 'Add new Coupon',
  //       route: { name: 'coupon-add' },
  //     },
  //   ],
  // },
  // {
  //   title: 'Promotion',
  //   icon: 'LoaderIcon',
  //   children: [
  //     {
  //       title: 'Promotion',
  //       route: { name: 'promotion-list' },
  //     },
  //     {
  //       title: 'Add new Promotion',
  //       route: { name: 'promotion-add' },
  //     },
  //   ],
  // },
  // {
  //   title: 'Contact',
  //   icon: 'PhoneCallIcon',
  //   children: [
  //     {
  //       title: 'Contact',
  //       route: { name: 'contact-list' },
  //     },
  //     {
  //       title: 'Add new Contact',
  //       route: { name: 'contact-add' },
  //     },
  //   ],
  // },
  // {
  {
    title: 'Setting',
    icon: 'SettingsIcon',
    children: [
      // {
      //   title: 'Language',
      //   route: { name: 'language' },
      // },
      {
        title: 'Config',
        route: { name: 'config' },
      },
    ],
  },
]
